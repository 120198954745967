.uploading {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.dropzone {
  border: 2px dashed #ddd;
}

.dropzone img {
  display: block;
  width: 100%;
}

.progress {
  position: absolute;
  left: 2em;
  right: 2em;
}
