.editable_embed {
  position: relative;
}

.editable_embed .edit {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  cursor: pointer;
}
