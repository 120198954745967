.bar {
  display: flex;
  height: 0.2em;
  margin-bottom: 5px;
}

.progress {
  background-color: #999999;
}

.remainder {
  background-color: #999999;
  opacity: 0.1;
}
