.suggestions {
  font-size: 0.7em;
  position: fixed;
  z-index: 10;
  margin: 0;
  padding: 0;
  list-style-type: none;
  border: 0.01em solid #999999;
  background-color: #fff;
}

.suggestions li {
  cursor: pointer;
  padding: 0.5em;
  color: #999999;
}

.suggestions li:hover {
  color: #fff;
  background-color: #4D4D4D;
}

.suggestions li.selected {
  color: #fff;
  background-color: #999999;
}
