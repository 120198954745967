.article_image {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1.5em;
  margin-bottom: 1.5em;
  position: relative;
}

.article_image img {
  position: absolute;
  top: 0;
  width: 100%;
}

.article_image > div {
  position: absolute;
  top: 0;
  border: 0.01em solid #ddd;
  color: #ddd;
  display: flex;
  align-items: center;
  justify-content: center;
}
