.composer {
  flex: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.composer * {
  -webkit-user-select: text;
}

.dropzone {
  display: flex;
  flex: 1;
  border: none;
  flex-direction: column;
}

.tools {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: #666666;
  padding-left: 0.2em;
  padding-right: 0.2em;

}

@media screen and (min-width:500px) {
  .tools {
    font-size: 1.2em;
 
  }
}

@media screen and (max-width:600px) {
  .tools {
    font-size: 1em;
  }
}

.tools a {
  position: relative;
  display: flex;
  padding: 0.25em;
  padding-left: 0.3em;
  padding-right: 0.3em;
}

.tools a .overlay {
  position: absolute;
  top: 0.3em;
  right: 0.3em;
  color: #999999;
  font-size: 0.8em;
}

.tools a.active {
  color: #fff;
}

.tools a.inactive {
  color: #E5E5E5;
}

.tools .left {
  display: flex;
  flex-direction: row;
}

.tools .right {
  display: flex;
  flex-direction: row;
}

.tools .tool_group {
  display: flex;
  flex-direction: row;
}

.writing_surface {
  background-color: #fff;
  flex: 1;
  padding: 1.5em;
  padding-top: 0.5em;
  font-size: 1.25em;
  overflow-y: auto;
}

.h2 {
  margin-top: 1em;
  margin-bottom: 1em;
}

.quote {
  color: #333;
  font-style: italic;
  margin: 1.5em;
  margin-left: 4em;
  margin-right: 2em;
  padding: 1.5em;
  border-top: #ddd solid 0.05em;
  border-bottom: #ddd solid 0.05em;
}

.quote > div {
  text-align: center;
}

ol li::before {
  color: #4D4D4D;
}

ul li::marker {
  color: #4D4D4D;
}

.unordered_list {
}

.paragraph {
  margin-top: 1em;
  margin-bottom: 1em;
}

.link {
  color: #999999;
  text-decoration: underline;
}

.suggestion {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.suggestion div {
  margin-right: 0.5em;
}
