.attachment_manager {

}

.attachment_manager .content {
  align-self: stretch;
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 1em;
}

.attachment_manager .attachments {
  display: flex;
  flex-direction: column;
  background-color: #dceddd;
  padding: 1em;
  margin-bottom: 1em;
  overflow-y: scroll;
  max-height: 50vh;
}

.attachment_manager .no_attachments {
  align-self: center;
}

.attachment_manager .dropzone {
  display: flex;
  flex: 1;
  padding-top: 10vh;
  padding-bottom: 10vh;
  border: 0.25em dashed #999999;
  justify-content: center;
  align-items: center;
}

.attachment_manager .accept {
  background-color: #dceddd;
}

.attachment_manager .reject {
  border: 0.25em dashed #4D4D4D;
  background-color: #ffdede;
}

.attachment_manager .attachment.uploading {
  color: #c90;
}

.attachment_manager .attachment.error {
  color: #4D4D4D;
}

.attachment_manager .error_details {
  font-style: italic;
}

.attachment_manager .attachment {
  display: flex;
  flex-direction: row;
  border-bottom: 0.1em solid #fff;
  padding: 0.5em;
  align-items: center;
}

.attachment_manager .attachment a {
  padding: 0.5em;
}

.attachment_manager .details {
  flex:1;
  display: flex;
  flex-direction: column;
  overflow:hidden;
  text-overflow: ellipsis;
}

.attachment_manager .info {
  display: flex;
  flex-direction: row;
  margin-bottom: 0.2em;
  flex: 1;
}

.attachment_manager .filename {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.attachment_manager .meta {
  margin-left: 0.5em;
  margin-right: 0.5em;
  opacity: 0.5;
}

.attachment_manager input {
  border: none;
  outline: none;
  background-color: #dddddd;
  border-bottom: 0.1em solid #999999;
  padding-left: 0;
  padding-right: 0;
}

.attachment_manager input::placeholder {
  color: #999999;
}
